import { useState } from "react";
import axios from "axios";
/** @jsx jsx */
import { css, jsx } from "@emotion/react"; // eslint-disable-line no-unused-vars
import tw from "tailwind.macro";

import ReCAPTCHA from "react-google-recaptcha";
import Layout from "~layouts/main";
import SEO from "~utils/seo";
import PageTitle from "~components/PageTitle";

const ContactPage = () => {
  const formCSS = css`
    ${tw`p-16`}

    label {
      ${tw`block text-blue-primary font-sans font-bold mb-4 text-lg`}
    }

    input,
    textarea {
      ${tw`appearance-none block w-full bg-gray-200 text-gray-700
      py-2 px-4 mb-6 outline-none border border-blue-primary`}
    }
    input:focus,
    textarea:focus {
      ${tw`bg-light border-blue-secondary`}
    }
  `;

  const [formFields, setFormFields] = useState({
    "first-name": "",
    "last-name": "",
    subject: "",
    email: "",
    message: "",
  });
  const [isFormValidated, setFormValidated] = useState(false);

  const updateFormField = (e, field) => {
    let newFormFields = { ...formFields };
    newFormFields[field] = e.target.value;
    setFormFields(newFormFields);
  };

  const [formErrors, setFormErrors] = useState([]);
  const [formResponse, setFormResponse] = useState(null);

  const submitForm = e => {
    e.preventDefault();
    if (checkFields()) {
      // Send the email
      const emailEndpoint =
        "https://getform.io/f/e138773e-0c03-4864-86eb-4523b8ae4794";
      axios
        .post(
          emailEndpoint,
          {
            name: formFields["first-name"] + " " + formFields["last-name"],
            subject: formFields["subject"],
            email: formFields["email"],
            message: formFields["message"],
          },
          { headers: { Accept: "application/json" } }
        )
        .then(response => {
          if (response.data.success) {
            setFormResponse(true);
          } else {
            setFormResponse(false);
          }
        })
        .catch(error => {
          setFormResponse(false);
        });
    }
  };
  const checkFields = () => {
    // Reset errors
    let errors = [];

    if (!formFields["first-name"]) errors.push("Please enter a first name");
    if (!formFields["last-name"]) errors.push("Please enter a last name");
    if (!formFields["subject"]) errors.push("Please enter a subject");
    // regex adapted form https://vuejs.org/v2/cookbook/form-validation.html#Using-Custom-Validation
    // eslint-disable-next-line no-useless-escape
    var emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRe.test(formFields["email"]))
      errors.push("Please enter a valid email address");
    if (!formFields["message"]) errors.push("Please enter a message");
    if (!isFormValidated) errors.push("Please complete the reCAPTCHA");

    setFormErrors(errors);

    return errors.length > 0 ? false : true;
  };

  return (
    <Layout>
      <SEO
        title="Contact | Paonix"
        description="Talk to us—we'd love to discuss your next great project."
      />

      <PageTitle
        title="Talk to us."
        subtitle="We'd love to discuss your next great project."
      />

      {formResponse === null ? (
        <section>
          {formErrors.length > 0 ? (
            <div className="flex flex-col items-center mb-16">
              <div>
                <div className="text-center text-orange-primary text-2xl">
                  <i className="fas fa-exclamation-circle"></i>
                  <h3>ERRORS</h3>
                </div>
                <ul className="my-1 ml-4 list-inside list-disc">
                  {formErrors.map(error => (
                    <li
                      key={error
                        .split(" ")
                        .join("-")
                        .toLowerCase()}
                    >
                      {error}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          <form onSubmit={submitForm} className="trans-all" css={formCSS}>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 md:pr-4">
                <label htmlFor="form-first-name">First Name</label>
                <input
                  id="form-first-name"
                  type="text"
                  placeholder="John"
                  onChange={e => updateFormField(e, "first-name")}
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-4">
                <label htmlFor="form-last-name">Last Name</label>
                <input
                  id="form-last-name"
                  type="text"
                  placeholder="Doe"
                  onChange={e => updateFormField(e, "last-name")}
                />
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full">
                <label htmlFor="form-subject">Subject</label>
                <input
                  id="form-subject"
                  type="text"
                  placeholder="My Next Project"
                  onChange={e => updateFormField(e, "subject")}
                />
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full">
                <label htmlFor="form-email">Email</label>
                <input
                  id="form-email"
                  type="email"
                  placeholder="john.doe@company.com"
                  onChange={e => updateFormField(e, "email")}
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-6">
              <div className="w-full">
                <label htmlFor="form-message">Message</label>
                <textarea
                  id="form-message"
                  type="text"
                  placeholder="Hi! I'd like to schedule a meeting with you."
                  onChange={e => updateFormField(e, "message")}
                />
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full">
                <label htmlFor="form-recaptcha">Verification</label>
                <ReCAPTCHA
                  sitekey="6Lef_VgUAAAAADq6wA4ex4pwOATLXjlPX1umlYeq"
                  onChange={() => setFormValidated(true)}
                />
              </div>
            </div>
            <div className="flex items-center mt-12">
              <button
                className="trans-all bg-orange-secondary hover:bg-orange-primary shadow-md hover:shadow-lg 
                      font-sans font-bold text-light rounded-sm px-4 py-2"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </section>
      ) : (
        <section className="p-16">
          <div className="container w-full mx-auto text-center">
            {formResponse === true ? (
              <div>
                <div className="text-green-400 text-3xl font-sans">
                  <span>Submission </span>
                  <span className="font-bold">
                    succeeded
                    <i className="fas fa-smile ml-2"></i>
                  </span>
                </div>
                <div className="mt-2">Thanks for the message!</div>
              </div>
            ) : (
              <div>
                <div className="text-red-600 text-3xl font-sans">
                  <span>Submission </span>
                  <span className="font-bold">
                    failed
                    <i className="fas fa-frown ml-2"></i>
                  </span>
                </div>
                <div className="mt-2">It's the thought that counts.</div>
              </div>
            )}
          </div>
        </section>
      )}
    </Layout>
  );
};

export default ContactPage;
